<template>
  <div class="home">
    <Header class="header" />
    <OnePage />
    <TwoPage />
    <ThreePage />
    <FourPage />
    <FivePage />
    <SixPage />
    <!-- <SevenPage /> -->
    <EightPage />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/one/header.vue";
import OnePage from "@/components/onePage.vue";
import TwoPage from "@/components/twoPage.vue";
import ThreePage from "@/components/threePage.vue";
import FourPage from "@/components/fourPage.vue";
import FivePage from "@/components/fivePage.vue";
import SixPage from "@/components/sixPage.vue";
// import SevenPage from "@/components/sevenPage.vue";
import EightPage from "@/components/eightPage.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    OnePage,
    TwoPage,
    ThreePage,
    FourPage,
    FivePage,
    SixPage,
    // SevenPage,
    EightPage,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.home {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 13;
  }
}
</style>
